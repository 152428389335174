<template>
  <footer class="site-footer px-3">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-5">
          <a id="logo"><img :src="MyLogo" height="30" /></a>
          <p class="text-justify">
            Food place is au courant Nigerian food delivery online company,
            where you have access to various food vendors, enjoy different
            cuisines a nd flavors from different restaurants without having to
            leave your comfort. We deliver to any location of your choice, we
            provide a wide range of options, with FOOD PLACE, you'll definitely
            find what you want.
          </p>
        </div>

        <div class="col-xs-6 col-md-2">
          <h6>About</h6>
          <ul class="footer-links">
            <li><a href="/">About US</a></li>
          </ul>
        </div>

        <div class="col-xs-6 col-md-2">
          <h6>Others</h6>
          <ul class="footer-links">
            <li><a href="/">News</a></li>
            <li><a href="">Blog</a></li>
          </ul>
        </div>

        <div class="col-xs-6 col-md-3">
          <h6>Contact Us</h6>
          <ul class="footer-links">
            <li>Telephone: +234 8061705599</li>
            <br />
            <li>
              Our office: No.35 Ajose Adeogun Street, Utako District Abuja
            </li>
            <br />
            <li>Office Hours: Mon – Fri: 8.00am – 5.00pm Sat-Sun: Closed</li>
            <br />
            <li>Website is available Monday-Sunday (round the clock)</li>
          </ul>
        </div>
      </div>
      <hr />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-sm-6 col-xs-12">
          <p class="copyright-text">
            &copy; <a href="#">Raadaa.com</a> 2017-2021 All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      MyLogo: require("../../assets/foodplace_white.png"),
    };
  },
};
</script>

<style scoped>
.row {
  --bs-gutter-x: 0rem;
}

.site-footer {
  background-color: #854D27; /* rgba(244, 244, 244, 1); */
  padding: 45px 0 20px;
  font-size: 15px;
  line-height: 24px;
  color: #fff;
  margin-top: 7%;
}
.site-footer hr {
  border-top-color: #bbb;
  opacity: 0.5;
}
.site-footer hr.small {
  margin: 20px 0;
}

.text-justify {
  padding-top: 7%;
}
.site-footer h6 {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px;
  padding-bottom: 5%;
}
.site-footer a {
  color: #fff; /* rgba(67, 66, 76, 1); */
  text-decoration: none;
}
.site-footer a:hover {
  color: rgba(67, 66, 76, 1);
  text-decoration: none;
}
.footer-links {
  padding-left: 0;
  list-style: none;
}
.footer-links li {
  display: block;
}
.footer-links a {
  color: #fff /* #737373; */
}
.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
  color: #3366cc;
  text-decoration: none;
}
.footer-links.inline li {
  display: inline-block;
}
.copyright-text {
  margin: 0;
  text-align: center;
}
@media (max-width: 991px) {
  .site-footer [class^="col-"] {
    margin-bottom: 30px;
  }
}
@media (max-width: 767px) {
  .site-footer {
    padding-bottom: 0;
  }
  .site-footer .copyright-text,
  .site-footer .social-icons {
    text-align: center;
  }
}
</style>
