<template>
  <div class="row" id="rider-container">
    <div class="col-md-6">
      <div class="banner-description">
        <h1>
          <span class="s-style">Become</span> FoodPlace
          <span class="s-style">Rider</span>
        </h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dui fusce
          eget velit enim adipiscing et. Risus risus nunc vitae in.
        </p>
        <Button
          id="banner-btn"
          @click="riderRegister()"
          btnText="Become a Rider"
        />
      </div>
    </div>

    <div id="banner-img" class="col-md-6">
      <img :src="rider" />
    </div>

    <div class="row" id="benefit-container">
      <div class="col-md-4">
        <div class="benefit">
          <div class="holder">
            <div><img :src="riderIcon" alt="" /></div>
            <div>You earn on every order delivered</div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="benefit">
          <div class="holder">
            <div><img :src="riderIcon" alt="" /></div>
            <div>You earn on every order delivered</div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="benefit">
          <div class="holder">
            <div><img :src="riderIcon" alt="" /></div>
            <div>You earn on every order delivered</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../../components/ReusableUI/SmalllButton.vue";
import router from "@/router";

export default {
  name: "RiderBanner",
  data() {
    return {
      rider: require("../../../assets/rider-banner.png"),
      riderIcon: require("../../../assets/b-rider.png"),
    };
  },

  methods: {
    riderRegister() {
      router.push({ name: "login" });
    },
  },

  components: {
    Button,
  },
};
</script>

<style scoped>
#banner-img img {
  width: 90%;
  height: auto;
}

#banner-btn {
  background-color: rgba(133, 77, 39, 1);
}

.s-style {
  color: rgba(133, 77, 39, 1);
}

.banner-description {
  /* text-align: center; */
  margin-top: 12%;
}

.banner-description h1 {
  font-weight: bold;
}

#rider-container {
  margin-top: 5%;
}

.benefit {
  background: #ffffff;
  box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 10px;
}

.holder {
  display: flex;
}

.holder img {
  background-color: rgba(251, 240, 232, 1);
  padding: 10px;
  border-radius: 8px;
  margin-right: 10px;
}

#benefit-container {
  margin-top: 6%;
  padding-left: 10px;
}
</style>
