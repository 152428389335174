<template>
  <nav id="background" class="navbar navbar-expand-lg navbar-light py-3">
    <div class="container-fluid" id="whole-container">
      <router-link to="/"><img :src="MyLogo" height="30"/></router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul id="menus" class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" href="#">About</a>
          </li>

          <li class="nav-item" id="m-item" @click="vendor()">
            <a class="nav-link active">Become a Vendor</a>
          </li>

          <li class="nav-item" @click="rider()">
            <a class="nav-link active">Become a Rider</a>
          </li>
        </ul>
        <div id="cart-icon" class="d-flex">
          <router-link id="btn" class="btn btn" to="/login">Login</router-link>
          <i class="fas fa-shopping-cart"></i>
          <span class="cart-count">0</span>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import router from "@/router";

export default {
  name: "Header",
  data() {
    return {
      MyLogo: require("../../assets/foodplace.png"),
    };
  },

  methods: {
    vendor() {
      router.push({ name: "vendor" });
    },

    rider() {
      router.push({ name: "rider" });
    },
  },
};
</script>

<style scoped>
#background {
  background-color: #fff;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
}

#logo {
  color: #854d27;
  font-weight: 600;
}

#btn {
  color: #854d27;
  padding: 3px 20px;
  border: 1px solid #854d27;
  outline-color: #854d27 !important;
  margin-right: 20%;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

#cart-icon i {
  padding-top: 8px;
  color: #854d27;
}

#menus {
  padding-left: 9%;
}

/* #menus li {
  padding-right: 29%;
} */

#menu a {
  color: #43424c;
}

#whole-container {
  margin: 0px 6.3%;
}

.cart-count {
  font-size: 15px;
  color: #854d27;
  padding: 0 5px;
  margin-left: -1px;
  background: rgba(248, 233, 242, 1);
  height: 20px;
  border-radius: 50%;
  width: 20px;
}

.nav-item a {
  padding: 8px 20px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 15px;
}

#menus li[data-v-40c8c946] {
  padding-right: 0%;
}

@media only screen and (max-width: 600px) {
  #whole-container {
    margin: 0px 20px;
  }
  #menus {
    padding-left: 0%;
  }

  #btn {
    margin-right: 10%;
  }
}
</style>
